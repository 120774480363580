/** 请求域名 */
export const devBase = 'https://quzhangfen.niurenjianzhan.com';
export const disBase = 'https://www.quzhangfen.com';
// export const disBase = 'http://192.168.7.85';

const isDis = true;

export const apiBase = isDis ? disBase : devBase;

/** 微信登录appId */
export const wxAppId = "wx8c93f20d64b80420";

export default {
  wxAppId,
  apiBase,
  uploadBaseUrl: `${apiBase}/api/m2227/62ddf0e4eadde`,
  uploadHeader: {
    'user-token': localStorage.getItem('token')
  },
}