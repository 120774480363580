/** 逻辑开关 */

/*
    是否打开合同开关
        1. 打开后购物车购买课程或直购课程之后，进入订单页再次进行提交支付
*/
export const IS_OPEN_HETONG = true;

/** 是否开启第三方直播课程功能 */
export const IS_OPEN_DISANFANG_LIVE = true;

/** 是否开启划线价 */
export const IS_OPEN_MARKET_PROCE = false;