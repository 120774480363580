<template>
    <div id="app">
        <router-view />
        <!-- 置顶 -->
        <el-backtop
            :right="0"
            :bottom="0"
            style="width:0;height: 0;box-shadow: none;"
            ref="globalBakcTop"
        />
    </div>
</template>

<script>
import TIM from 'tim-js-sdk';
import { answerPageUrl } from "@/const";
export default {
    created() {
        this.$store.commit('init');
        this.$store.dispatch('getWebInfo');
        this.imEventInit(this.eventBus, this.$Tim);
        this.imLogin();
        this.eventBus.$on('globalPageToTop', () => this.globalPageToTop())

        window.addEventListener("storage", this.moreBarHandler)
        window.addEventListener("beforeunload", () => {
            window.removeEventListener("beforeunload", this.moreBarHandler)
        })
    },
    beforeDestroy() { this.eventBus.$off('globalPageToTop'); },
    methods: {
        moreBarHandler(e) {
            if (e.key == "START_ANSWER_BNIAO_SHI" && answerPageUrl.includes(this.$route.path)) {
                this.$router.replace("/home");
                this.$msg("开始了新的答题", "e");
            }
        },
        /** 页面回到顶部 */
        globalPageToTop() { this.$refs.globalBakcTop.scrollToTop() },
        /** IM 登录 */
        imLogin() {
            var token = this.$store.state.token || '';
            var userInfo = this.$store.state.userInfo || {};
            if (!token) return;
            this.$Tim.login({ userID: userInfo.t_user_id, userSig: userInfo.user_sig })
        },
        /**
         * IM监听初始化
         * @param Object eventBus 全局事件对象
         * @param Object tim IM实例对象
         */
        imEventInit(eventBus, tim) {
            // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
            tim.on(TIM.EVENT.SDK_READY, () => {
                this.$store.commit('imReadyStatusChange', true);
            });
            // SDK NOT READT
            tim.on(TIM.EVENT.SDK_NOT_READY, () => { console.debug('SDK_NOT_READY') });
            // 被踢出
            tim.on(TIM.EVENT.KICKED_OUT, () => {
                this.$store.commit('loginOut');
                this.$router.replace('/login');
                this.$Tim.logout();
            });
            // SDK内部出错
            tim.on(TIM.EVENT.ERROR, () => {
                this.$store.commit('loginOut');
                this.$router.replace('/login');
                console.debug('ERROR')
            });
            // 收到新消息
            tim.on(TIM.EVENT.MESSAGE_RECEIVED, () => { console.debug('MESSAGE_RECEIVED') });
            // 会话列表更新
            tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, message => {
                var info = message.data.find(t => t.type == "GROUP");
                if (info) {
                    info = info.lastMessage;
                    eventBus.$emit('addGroupText', {
                        from: info.fromAccount,
                        nick: info.nick,
                        payload: info.payload
                    });
                }
            });
            // 群组列表更新
            tim.on(TIM.EVENT.GROUP_LIST_UPDATED, res => {
                // console.debug('GROUP_LIST_UPDATED');
                eventBus.$emit('updateGroupInfo', res.data[0]);
            });
            // 网络监测
            tim.on(TIM.EVENT.NET_STATE_CHANGE, () => { console.debug('NET_STATE_CHANGE') });
            // 已读回执
            tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, () => { console.debug('MESSAGE_READ_BY_PEER') });
            // 黑名单更新
            tim.on(TIM.EVENT.FRIEND_LIST_UPDATED, () => { console.debug('FRIEND_LIST_UPDATED') });
            // 好友申请列表更新
            tim.on(TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED, () => { console.debug('FRIEND_APPLICATION_LIST_UPDATED') });
            // 好友分组列表更新
            tim.on(TIM.EVENT.FRIEND_GROUP_LIST_UPDATED, () => { console.debug('FRIEND_GROUP_LIST_UPDATED') });
        },
    }
}
</script>

<style lang="less">
@import './common/css/global.less';
@import './common/css/flex.less';
@import './common/css/animate.min.css';

@font-face {
 font-family: 'xiaoxingxing';
 src: url('~@/assets/fonts/SourceHanSansSC-Normal-2.otf');
}

@font-face {
 font-family: zcoolwenyiti;
 src: url('~@/common/font/ZhanKuWenYiTi-2.ttf');
}
// 思源
#app {
 font-size: 14px;
 font-family: 'xiaoxingxing';
 font-weight: 400;
}

.container {
 width: 1200px;
 margin: 0 auto;
}

.topic_new_jiexi_box {
 img {
  max-width: 100%;
 }
}
</style>
