import Vue from 'vue'
import VueRouter from 'vue-router'
import { Notification } from 'element-ui';
import { answerPageUrl } from "@/const";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: resolve => require(['@/views/home/home.vue'], resolve),
    children: [
      // 首页
      {
        path: '',
        component: resolve => require(['@/views/home/children/index.vue'], resolve),
      },
      // 下载APP
      {
        path: 'download',
        component: resolve => require(['@/views/home/children/download.vue'], resolve),
      },
      // 资料购买
      {
        path: 'datapurchase',
        component: resolve => require(['@/views/home/children/dataPurchase.vue'], resolve),
      },
      // 考试资讯
      {
        path: 'examinationConsultation',
        component: resolve => require(['@/views/home/children/examinationConsultation.vue'], resolve),
      },
      // 学习中心
      {
        path: 'studyCenter',
        component: resolve => require(['@/views/home/children/studyCenter.vue'], resolve),
      },
      // 购物车
      {
        path: 'cart',
        component: resolve => require(['@/views/home/children/cart.vue'], resolve),
      },
      // 课程列表
      {
        path: 'goodMore',
        component: resolve => require(['@/views/home/children/goodMore.vue'], resolve),
      },
      // 公开课列表
      {
        path: 'goodMore2',
        component: resolve => require(['@/views/home/children/goodMore2.vue'], resolve),
      },
      // 搜索课程表
      {
        path: 'search',
        component: resolve => require(['@/views/home/children/search.vue'], resolve),
      },
      // 课程详情
      {
        path: 'goodDetail',
        component: resolve => require(['@/views/home/children/goodDetail.vue'], resolve),
      },
      // 提交订单
      {
        path: 'createOrder',
        component: resolve => require(['@/views/home/children/createOrder.vue'], resolve),
      },
      // 创建购物车订单
      {
        path: 'createCarOrder',
        component: resolve => require(['@/views/home/children/createCarOrder.vue'], resolve),
      },
      // 资料详情
      {
        path: 'dataPurchaseDetail',
        component: resolve => require(['@/views/home/children/dataPurchaseDetail.vue'], resolve),
      },
      // 支付
      {
        path: 'pay',
        component: resolve => require(['@/views/home/children/pay.vue'], resolve),
      },
      // 我的
      {
        path: 'mine',
        component: resolve => require(['@/views/home/children/mine/mine.vue'], resolve),
        children: [
          {
            path: '',
            redirect: 'myClass'
          },
          // 我的课程
          {
            path: 'myClass',
            component: resolve => require(['@/views/home/children/mine/_children/myClass.vue'], resolve),
          },
          // 我的课程表
          {
            path: 'kechengbiao',
            component: resolve => require(['@/views/home/children/mine/_children/kechengbiao.vue'], resolve),
          },
          // 团购订单
          {
            path: 'timOrder',
            component: resolve => require(['@/views/home/children/mine/_children/timOrder.vue'], resolve),
          },
          // 我的订单
          {
            path: 'order',
            component: resolve => require(['@/views/home/children/mine/_children/order.vue'], resolve),
          },
          // 我的钱包
          {
            path: 'money',
            component: resolve => require(['@/views/home/children/mine/_children/money.vue'], resolve),
          },
          // 掌分币
          {
            path: 'zhangfenbi',
            component: resolve => require(['@/views/home/children/mine/_children/zhangfenbi.vue'], resolve),
          },
          // 优惠券
          {
            path: 'coupon',
            component: resolve => require(['@/views/home/children/mine/_children/coupon.vue'], resolve),
          },
          // 账户设置
          {
            path: 'member',
            component: resolve => require(['@/views/home/children/mine/_children/member.vue'], resolve),
            children: [
              {
                path: '',
                redirect: 'info'
              },
              // 用户信息
              {
                path: 'info',
                component: resolve => require(['@/views/home/children/mine/_children/_children/member/memberInfo/memberInfo.vue'], resolve),
              },
              // 更新登录密码
              {
                path: 'updateLoginPwd',
                component: resolve => require(['@/views/home/children/mine/_children/_children/member/updateLoginPwd.vue'], resolve),
              },
              // 更新支付密码
              {
                path: 'updatePayPwd',
                component: resolve => require(['@/views/home/children/mine/_children/_children/member/updatePayPwd.vue'], resolve),
              },
            ]
          },
          // 地址管理
          {
            path: 'address',
            component: resolve => require(['@/views/home/children/mine/_children/address/index.vue'], resolve),
            children: [
              {
                path: '',
                redirect: 'list'
              },
              {
                path: 'list',
                component: resolve => require(['@/views/home/children/mine/_children/address/list.vue'], resolve),
              },
              {
                path: 'form',
                component: resolve => require(['@/views/home/children/mine/_children/address/form.vue'], resolve),
              }
            ]
          },
          // 我的消息
          {
            path: 'notice',
            component: resolve => require(['@/views/home/children/mine/_children/notice.vue'], resolve),
          },
          // 我的合同
          {
            path: 'contract',
            component: resolve => require(['@/views/home/children/mine/_children/contract.vue'], resolve),
          },
          // 考试资讯
          {
            path: 'zixunList',
            component: resolve => require(['@/views/home/children/mine/_children/zixunList.vue'], resolve),
          },
          // 我的发票
          {
            path: 'invoice',
            component: resolve => require(['@/views/home/children/mine/_children/invoice/invoice.vue'], resolve),
          },
          // 考试资讯
          {
            path: 'examinationNotice',
            component: resolve => require(['@/views/home/children/mine/_children/examinationNotice.vue'], resolve),
          },
          // 入学通知书
          {
            path: 'admissionNotice',
            component: resolve => require(['@/views/home/children/mine/_children/admissionNotice.vue'], resolve),
          },
        ]
      },
      //  开始学习
      {
        path: 'study',
        component: resolve => require(['@/views/home/children/study/study.vue'], resolve),
        children: [
          {
            path: '',
            redirect: 'examinationSite'
          },
          {
            path: 'courseList',
            component: resolve => require(['@/views/home/children/study/_children/courseList.vue'], resolve),
          },
          {
            path: 'examinationSite',
            component: resolve => require(['@/views/home/children/study/_children/examinationSite.vue'], resolve),
          },
          {
            path: 'playback',
            component: resolve => require(['@/views/home/children/study/_children/playback.vue'], resolve),
          },
          {
            path: 'datiRecard',
            component: resolve => require(['@/views/home/children/study/_children/datiRecard.vue'], resolve),
          },
          {
            path: 'subject',
            component: resolve => require(['@/views/home/children/study/_children/subject.vue'], resolve),
          },
          {
            path: 'predictionQuestion',
            component: resolve => require(['@/views/home/children/study/_children/predictionQuestion.vue'], resolve),
          },
          {
            path: 'forgetSubject',
            component: resolve => require(['@/views/home/children/study/_children/forgetSubject.vue'], resolve),
          },
          {
            path: 'wrongSubject',
            component: resolve => require(['@/views/home/children/study/_children/wrongSubject.vue'], resolve),
          },
          {
            path: 'recordingAndBroadcasting',
            component: resolve => require(['@/views/home/children/study/_children/recordingAndBroadcasting.vue'], resolve),
          },
          {
            path: 'liveBroadcast',
            component: resolve => require(['@/views/home/children/study/_children/liveBroadcast.vue'], resolve),
          },
          {
            path: 'secretTraining',
            component: resolve => require(['@/views/home/children/study/_children/secretTraining.vue'], resolve),
          }
        ]
      },
      // 答题结果查看
      {
        path: 'answerResult',
        component: resolve => require(['@/views/home/children/answerResult.vue'], resolve)
      },
      // 考试答题结果
      {
        path: 'previewKaoshiRecard',
        component: resolve => require(['@/views/home/children/previewKaoshiRecard.vue'], resolve),
      },
      // 刷题
      {
        path: 'answerTest',
        component: resolve => require(['@/views/home/children/answerTest.vue'], resolve)
      },
      // 查看收藏试题
      {
        path: 'answerTestCollect',
        component: resolve => require(['@/views/home/children/answerTestCollect.vue'], resolve),
      },
      // 查看错题
      {
        path: 'answerTestError',
        component: resolve => require(['@/views/home/children/answerTestError.vue'], resolve),
      },
      // 试卷答题
      {
        path: 'answerTestPaper',
        component: resolve => require(['@/views/home/children/answerTestPaper.vue'], resolve)
      },
      // 试卷答题结果
      {
        path: 'answerTestPaperResult',
        component: resolve => require(['@/views/home/children/answerTestPaperResult.vue'], resolve)
      },
      // 我的收藏
      {
        path: 'collection',
        component: resolve => require(['@/views/home/children/collection.vue'], resolve)
      },
      // 查看错题
      {
        path: 'error',
        component: resolve => require(['@/views/home/children/error.vue'], resolve)
      },
      // 订单详情
      {
        path: 'orderDetail',
        component: resolve => require(['@/views/home/children/orderDetail.vue'], resolve)
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: resolve => require(['@/views/login/login.vue'], resolve),
    children: [
      // 登录
      {
        path: '',
        component: resolve => require(['@/views/login/children/index/index.vue'], resolve),
      },
      // 忘记密码
      {
        path: 'forgotpassword',
        component: resolve => require(['@/views/login/children/forgotPassword/forgotPassword.vue'], resolve),
      },
      // 绑定手机号
      {
        path: 'bindPhone',
        component: resolve => require(['@/views/login/children/bindPhone/bindPhone.vue'], resolve),
      }
    ]
  },
  // 视频播放【课程学习使用】
  {
    path: '/videoPlay',
    component: resolve => require(['@/views/videoPlay/videoPlay.vue'], resolve),
  },
  // 看直播
  {
    path: '/livePlay',
    component: resolve => require(['@/views/livePlay/livePlay.vue'], resolve),
  },
  {
    path: '/example',
    component: resolve => require(['@/views/example.vue'], resolve),
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 白名单
const whiteRoute = [
  '/home',
  '/home/download',
  '/home/datapurchase',
  '/home/goodMore',
  '/home/goodDetail',
  '/login',
  '/login/forgotpassword',
  '/login/bindPhone'
]

// 路由拦截
router.beforeEach((to, form, next) => {
  if (answerPageUrl.includes(to.path)) localStorage.setItem("START_ANSWER_BNIAO_SHI", Math.random())
  var tk = localStorage.getItem('token');
  if (!tk && !whiteRoute.some(t => t == to.path)) next({ path: '/login' })
  else next()
  // next()
})

export default router
