/** 直播状态 */
export const LIVE_STATUS = {
    /** 未开播 */
    NOT_START: 1,
    /** 直播中 */
    PLAYING: 2,
    /** 已结束 */
    END: 3,
    /** 已取消 */
    CANCEL: 4,
    /** 已生成回放 */
    GENERATED: 5
}

/** 答题页面路由 */
export const answerPageUrl = ["/home/answerTestPaper", "/home/answerTest"];